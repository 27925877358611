.joining-page {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5; /* or any color you want for the background */
}

.joining-container {
    max-width: 1150px;
    text-align: left;
    background-color: #fff; /* or any color you want for the text container */
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    border-radius: 8px; /* Optional: rounds the corners */
    margin: auto;
}

.joining-container h2 {
    color: #A41034; /* or any color you want for the heading */
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.joining-container p {
    color: #333; /* or any color you want for the paragraph text */
    line-height: 1.6em;
}

.joining-container p strong {
    color: #A41034; /* or any color you want for the emphasized text */
}
