@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Header.css */

.header {
    display: flex;
    justify-content: center; /* Align the container center */
    background-color: #fff; /* Adjust the color to match your design */
    
}

.active {
    color: blue; /* or any style you want for the active link */
  }  

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 95px; /* clamp(80px, calc(80px + (15 * ((100vw - 1024px) / (1215 - 1024)))), 95px); */ /*This is for the height to continuously decrease from 95 to 80px from 1215 - 1024px) */
    max-width: 1150px; /* Adjust the max-width as needed */
    margin: 0 auto; /* This will center the container */
}

.logo img {
    width: 300px; /* Adjust the size to fit your design */
    height: auto;
}

.navigation ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navigation li {
    margin-left: 20px; /* Space between menu items */
}

.navigation a {
    text-decoration: none;
    color: #4a4a4a;
}

.navigation a:hover {
    color: #A41034;
}

.hamburger {
    display: none; /* Hide by default, will be displayed in mobile view */
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    width: 16px;
    height: 15px;
    position: absolute; /* Absolute positioning relative to its parent */
    top: 7px; /* Align to the top of the parent */
    right: 7px; /* Align to the right of the parent */
    padding: 10px; /* Padding to ensure the touch area is large enough */
}

.hamburger span {
    display: block;
    height: 1px; /* Thinner line for less bold look */
    width: 100%; /* Full width of the parent */
    background: #333; /* Line color */
    transition: all 0.3s ease; /* Smooth transition for animations */
}

.hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(3.5px, 3.5px); /* Adjusted for smaller size */
}

.hamburger.active span:nth-child(2) {
    opacity: 0; /* Middle line disappears */
}

.hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(3.5px, -3.5px); /* Adjusted for smaller size */
}

/* Medium Devices (Tablets): 768px to 991px */
@media (max-width: 1215px) {
    .header-container {
        max-width: 1000px;
        /* ... other responsive adjustments ... */
    }
    /* ... */
}

/* Responsive Styles */
@media (max-width: 1023px) {
    .header-container {
        margin-left: 2em;
        height: 80px;
        max-width: 1000px;
        /* ... other responsive adjustments ... */
    }
    .hamburger {
        display: flex; /* Show hamburger icon in mobile view */
    }
    .navigation {
        position: absolute;
        top: 80px; /* Adjust this value based on your header's height */
        left: 0;
        width: 100%;
        background: #fff; /* Background color of dropdown */
        box-shadow: 0 8px 16px rgba(0,0,0,0.1); /* Optional: adds a shadow */
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0.75em;
        padding-bottom: 0.75em;

        display: none; /* Hide the navigation initially */
    }
    .navigation.visible {
        display: flex; /* Show navigation when active */
    }
    .navigation ul {
        width: 100%; /* Full width */
        flex-direction: column;
    }
    .navigation li {
        width: 100%; /* Full width */
        text-align: left; /* Align text to the left */
    }
    .navigation a {
        color: #4a4a4a;
        width: 100%; /* Full width */
        padding: 0px; /* Add padding */
        line-height: 2;
        text-decoration: none; /* Remove underline from links */

    }
}

/* ... other styles ... */

.main-section {
    background-color: #f5f5f5;
    display: flex; /* Added to layout the children side by side */
    align-items: center; /* This ensures vertical centering of children */
    
}

.mainsection-tab-container {
    display: flex;
    align-items: center;
    height: 350px;
    border-radius: 4px; /* Optional: if you want rounded corners */
    overflow: hidden; /* Ensures the content respects the border radius */
    max-width: 1150px; /* Adjust this value as needed for your max-width */
    margin-left: auto; /* Centers the section horizontally */
    margin-right: auto; /* Centers the section horizontally */
}

.main-section-content {
    flex: 1 1;
    margin-right: -125px;
    text-align: left; /* Align the text to the left */
}

.image-container {
    flex: 1 1;
    display: flex;
    margin-left: 0px;
    justify-content: flex-end;
}

.image-container img {
    max-width: 100%;
    width: 461px;
    height: auto;
    border-radius: 4px; /* Optional: if you want rounded corners on the image */
}

.maintitle {
    font-weight: 400;
    color: #A41034; 
    font-size: 1.5em; /* 1.5 times the size of the current font context */
    /* Any additional styling you want for your title */
}

.main-section p {
    line-height: 1.5;
    color: #4a4a4a;
    margin-top: 1.5em;
}

.main-section a {
    color: #A41034;
    text-decoration: none;
}

.main-section a:hover {
    text-decoration: underline;
}

@media (max-width: 1215px) {

    .mainsection-tab-container {
        max-width: 1000px; /* Adjust this value as needed for your max-width */
        height: 375px;
    }

    .main-section-content {
        padding-right: 100px;
    }

    /* If you have other specific styles that affect the width or offset of these elements, reset them here */
}

@media (max-width: 1023px) {
    .mainsection-tab-container {
        flex-direction: column;
        padding: 2em;
        height: auto;
    }
    .main-section-content {
        margin-bottom: 1em;
    }
    .image-container {
        justify-content: flex-start;
        margin-right: auto;
    }
}

.news-section {
    background-color: #f5f5f5;
    width: 100%; /* Adjust width as needed */
    margin: auto;
    text-align: center; /* This will center the title */
    padding-left: 0.5em;
    padding-top: 3em;
    padding-bottom: 1em;
}

.news-title {
    margin-bottom: 1em;
    text-align: center; /* Centers the title */
    font-size: 2em;
    color: #4a4a4a;
}

.news-items-container {
    max-width: 1000px; /* Adjust this value as needed for your max-width */
    text-align: left;
    margin-left: auto ; /* Centers the section horizontally */
    margin-right: auto; /* Centers the section horizontally */
}

.news-item {
    display: flex;
    margin-bottom: 1.5rem; /* Space between news items */
    align-items: baseline; /* Aligns items on their baseline */
    color: #4a4a4a;
}

.news-date {
    flex-basis: 150px; /* Adjust the width as necessary */
    flex-shrink: 0; /* Prevents the date from shrinking */
    margin-right: 1rem; /* Space after the date */
}

.news-content {
    flex-grow: 1; /* Allows the content to fill the remaining space */
}

.news-content a {
    color: #A41034; 
    text-decoration: none; /* Optional: removes the underline from links */
}

.news-content a:hover {
    text-decoration: underline; /* Optional: adds an underline on hover */
}

@media (max-width: 1023px) {
    .news-date {
        margin-right: -2.5em; /* Space after the date */
    }

    .news-item {
        margin-left: 1.5em; /* Space between news items */
        margin-right: 1.5em;
        align-items: baseline; /* Aligns items on their baseline */
    }
}
.people-page {
    display: flex;
    justify-content: center; /* This will center the .people-container */
    background-color: #f5f5f5;
}
.people-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1150px; /* Adjust the width as needed */
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
}

/* Styles for the faculty list */
.faculty-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4em;
    gap: 4em; /* Adjust the space between cards as needed */
  }
  
  /* Styles for individual faculty member cards */
  .faculty-member {
    /* Adding a flex container to align items vertically */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 166.4px; /* Set the width of the card to match the image */
  }
  
  /* Styles for faculty images */
  .faculty-member img {
    width: 166.4px; /* Exact width for the image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Optional: Adds rounded corners to the image */
    margin-bottom: 5px; /* Space between image and text */
  }
  
  /* Styles for the text information about the faculty */
  .faculty-info {
    text-align: center;
  }
  
  /* Styles for the faculty name (h3) */
  .faculty-info h3 {
    margin: 5px 0;
    font-size: 1em; /* Adjust font size as needed */
  }
  
  .faculty-name-link:hover {
    text-decoration: underline; /* Typically, links are underlined on hover for usability */
  }

  /* Styles for the faculty title and department (paragraph) */
  .faculty-info p {
    margin: 5px 0;
    font-size: 1em; /* Adjust font size as needed */
    color: #555; /* Adjust text color as needed */
  }

  .people-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; /* Aligns items to the start of the container */
    padding: 0;
    margin: 0;
    list-style: none;
    grid-gap: 3em;
    gap: 3em; /* Adjust the space between cards as needed */
  }
  
  .people-member {
    width: 250px;
    display: flex;
    flex-direction: row; /* Adjusts direction to row instead of column */
    align-items: flex-start; /* Centers items vertically */
  }
  
  .people-member img {
    width: 100px; /* As per your previous request */
    height: auto; /* Maintain aspect ratio */
    border-radius: 4px; /* Optional: Adds rounded corners to the image */
    margin-right: 20px; /* Adds space between the image and the text */
  }
  
  .people-info {
    margin-right: -1em; 
    text-align: left; /* Aligns the text to the left */
  }
  
  .people-name-link {
    color: black; /* Initial color */
    text-decoration: none; /* No underline */
  }

  .people-name-link:link:hover {
    text-decoration: underline; /* Typically, links are underlined on hover for usability */
  }
  
  .people-name-link h3 {
    margin: 0; /* Remove default margin from h3 */
    margin-bottom: 0.25em;
    font-size: 1rem; /* Adjust to match your design */
  }
  
  .people-member p {
    font-size: 1rem; /* Adjust to match your design */
    color: #555; /* Adjust text color as needed */
    line-height: 1.25em;
    margin: 0; /* Adjust or remove margin as needed */
  }  

.sidebar {
    flex: 0 0 200px;
    padding: 20px;
    
}

.sidebar a {
    display: block;
    margin-bottom: 1em;
    color: #A41034; /* Adjust the color as needed */
    text-decoration: none; /* Removes underline */
    cursor: pointer;
}

.sidebar a:hover {
    text-decoration: underline; /* Adds underline on hover */
}

.main-content {
    flex: 3 1;
    padding: 20px;
}

section {
    margin-bottom: 25px; /* Space between sections */
}

section h2 {
    margin-bottom: 20px;
}

.alumni-list {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; /* Aligns items to the start of the container */
  padding: 0;
  margin: 0;
  list-style: none;
  grid-gap: 3em;
  gap: 3em; /* Adjust the space between cards as needed */
}

@media (max-width: 462px) {
    .main-content {
        margin-top: -1.5em;
    }
    /* ... */
}

/* Adjustments for the faculty grid display */
/* You may need to add more CSS to style the faculty member list as a grid */

.joining-page {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5; /* or any color you want for the background */
}

.joining-container {
    max-width: 1150px;
    text-align: left;
    background-color: #fff; /* or any color you want for the text container */
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    border-radius: 8px; /* Optional: rounds the corners */
    margin: auto;
}

.joining-container h2 {
    color: #A41034; /* or any color you want for the heading */
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.joining-container p {
    color: #333; /* or any color you want for the paragraph text */
    line-height: 1.6em;
}

.joining-container p strong {
    color: #A41034; /* or any color you want for the emphasized text */
}

.search-bar {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    width: calc(100% - 22px); /* Adjust for padding and border */
}

.tag-filter {
    margin-bottom: 20px;
}

.tag {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #e9e9e9;
    transition: background-color 0.3s;
}

.tag.selected {
    background-color: #a41034;
    color: white;
}

.publications-list {
    padding: 0px;
}

.pubjournalyear {
    font-style: italic;
    margin-bottom: 0.15em; /* Adjust this value as needed to increase/decrease space */
}

.pubpeople {
    margin-bottom: 0.15em; /* Adjust this value as needed to increase/decrease space */
}

.publication-item {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.publication-row {
    display: flex;
    align-items: flex-start;
    position: relative; /* Needed for absolute positioning of the abbrev box */
    /* padding-left: 60px; /* Adjust based on the width of your abbrev-box */
}

.box-wrapper {
    width: 8em; /* Fixed width: abbrev box width plus margin */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-shrink: 0; /* Prevent shrinking */
    /* margin-right: 5em; /* Space between this wrapper and the publication details */
}

.abbrev-box {
    left: 0; /* Aligns the abbrev-box to the far left */
    top: 0;
    margin-left: 0em;
    margin-right: auto; /* Pushes the box to the left and margin to the right */
    background-color: #64b5f6; /* Blue */
    text-align: center;
    padding: 0.2em 0.8em;
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px;
    font-size: 0.8em;

}

.award-box {
    background-color: #ffd700; /* Example color for awards, like gold */
    padding: 0.2em 0.8em;
    margin-top: 0.4em; /* Spacing between the abbrev-box and the award-box */
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px;
    font-size: 0.8em;
    margin-right: auto;

    /* Other styles as needed */
}

.pubtitle {
    font-weight: bold;
    color: #333; 
    font-size: 1em; /* 1.5 times the size of the current font context */
    padding-bottom: 0.25em;
}

.publication-buttons {
    display: flex; /* Use flexbox to layout tags */
    flex-wrap: wrap; /* Allow tags to wrap to the next line */
    grid-gap: 0.5em;
    gap: 0.5em; /* Optional: adds space between tags */
    margin-top: 10px;
    margin-bottom: 10px;
}

.button {
    padding: 0.4em 0.8em;
    margin-right: 1em;
    text-decoration: none;
    color: #fff;
    border-radius: 5px;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.2s;
}

.pdf {
    background-color: #e57373; /* Red */
}

.video {
    background-color: #ed1b34; 
}

.highlight {
    background-color: #81c784; /* Green */
}

.code {
    background-color: #ba68c8; /* Purple */
}

.award {
    background-color: #ffd54f; /* Amber */
}

.news {
    background-color: #26a69a; /* Teal */
}

.project {
    background-color: #eb7d00; /* Orange */
}

.button:hover {
    opacity: 0.6;
}

@media (max-width: 802px) {
    
    .publication-row {
        /* Change the direction to column so items stack vertically */
        flex-direction: column;

    }

    .box-wrapper {
        /* Align the boxes horizontally */
        flex-direction: row;
        align-items: flex-start;
    }

    .abbrev-box, .award-box {
        align-self: center;
        margin-top: 0em;
    }

    .button {
        margin-bottom: 0.5em;
    }
}

.publications-page {
    background-color: #f5f5f5;
    padding: 20px;
}

.publications-container {
    max-width: 1150px; /* Adjust this value as needed for your max-width */
    margin-left: auto ; /* Centers the section horizontally */
    margin-right: auto; /* Centers the section horizontally */
}

/* Additional layout and spacing for the title, search bar, and tag filter */

/* Layout for the tag filter section */
.tag-filter {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 0.5em;
    row-gap: 0.5em;
    justify-content: center; /* Centers tags if they don't span the full width */
    margin-bottom: 1.5rem; /* Space below the tag filter */
}

/* Adjust the spacing for the last item in the publications list */
.publications-list .publication-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

/* This will add extra space at the bottom of the .publications-page */
.publications-page {
    padding-top: 1.5em;
    padding-bottom: 1.5em; /* Adds extra space at the bottom inside the grey box */
}

.footer {
  padding: 2em;
  padding-bottom: 1em; /* Increase this value to add more space, or decrease it to reduce space */
  position: relative;
}

.footer-heading-container {
  max-width: 1150px; /* Adjust max-width as needed */
  margin: 0 auto; /* Centers the containers */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align children to the top */
  max-width: 1150px; /* Adjust max-width as needed */
  margin: 0 auto; /* Centers the container */
}

.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
  align-items: flex-end; /* Aligns content to the right */
}

p {
  margin: 0 0 0.5em 0; /* Adjust bottom margin as needed for spacing */
  line-height: 1; /* Single line spacing */
}

h2 {
  color: #A41034;
  margin: 0 0 1em 0; /* Adjust as needed, adds space below the heading */
}

a {
  color: inherit;
  text-decoration: none;
}

/* Responsive design adjustments */
@media (max-width: 1023px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-left, .footer-right {
    width: 100%;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 1em; /* Adds space between the left and right sections on smaller screens */
  }

  .footer-right {
    margin-bottom: 0; /* Removes extra space below the right section on smaller screens */
  }
}

.postal-code {
  word-break: keep-all; /* This will prevent the number from breaking mid-number */
}

@media (max-width: 409px) {
  .postal-code {
      display: inline-block; /* Treat the postal code as a block to add margin */
      margin-top: 0.5em; /* Adjust the top margin to control spacing after wrap */
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

html, body, #root {
  height: 100%;
  margin: 0;
  font-family: "Helvetica Neue", sans-serif; /* Add this line */
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



