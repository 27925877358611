.publications-page {
    background-color: #f5f5f5;
    padding: 20px;
}

.publications-container {
    max-width: 1150px; /* Adjust this value as needed for your max-width */
    margin-left: auto ; /* Centers the section horizontally */
    margin-right: auto; /* Centers the section horizontally */
}

/* Additional layout and spacing for the title, search bar, and tag filter */

/* Layout for the tag filter section */
.tag-filter {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5em;
    justify-content: center; /* Centers tags if they don't span the full width */
    margin-bottom: 1.5rem; /* Space below the tag filter */
}

/* Adjust the spacing for the last item in the publications list */
.publications-list .publication-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

/* This will add extra space at the bottom of the .publications-page */
.publications-page {
    padding-top: 1.5em;
    padding-bottom: 1.5em; /* Adds extra space at the bottom inside the grey box */
}
